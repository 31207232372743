import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { DCard } from '../organisms/DCard';
import './Dashboard.css';
import { DDateTimeCard } from '../organisms/DDateTimeCard';
import { DGarbageCard } from '../organisms/DGabageCard';
import { DBusSchedule } from '../organisms/DBusSchedule';
import { DWeatherCard } from '../organisms/DWeatherCard';



export const Dashboard: React.FC = () => {
  return (
    <div className='Dashboard-page'>
      <Grid container className='Dashboard-container' rowSpacing={3} alignItems={'center'} justifyContent={'center'}>
        <Grid item container xs={6} md={6} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DCard title={'My House Dashboard '} subTitle={'まだできてないです'} message={'早く実装して'} height={250} width={470} backgroundColor={'#EC663B'}/>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DDateTimeCard height={250} width={230}/>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DGarbageCard height={250} width={230}/>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DBusSchedule height={250} width={230} backgroundColor={'#9C4274'}></DBusSchedule>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DWeatherCard height={250} width={230}/>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DCard title={'為替'} message={'test test test'} height={250} width={230}/>
        </Grid>
        <Grid item container xs={3} md={3} sm={6} spacing={1} className="Dashboard-grid-item" justifyContent={'center'}>
          <DCard title={'テストtitle'} message={'test test test'} height={250} width={230}/>
        </Grid>
      </Grid>
    </div>
  )
}