import React, { useState, useEffect } from 'react'
import {Card, CardContent, Typography} from '@mui/material'
import { DCard, DCardProps } from './DCard';
import { AnalogClock } from '../molecules/AnalogClock';
import { useSeconds } from '../../hooks/Dashboard/TimeEffects';

export type DDateTimeCardProps = {
  height: number;
  width: number;
};

export const DDateTimeCard: React.FC<DDateTimeCardProps> = (props: DDateTimeCardProps) => {
  const time = useSeconds();

  return (
    <DCard title={'現在時刻'} subTitle={(new Date(time)).toLocaleString()} message={''} height={props.height} width={props.width} backgroundColor={'#48BCB2'}>
      <AnalogClock time={time}/>
    </DCard>
  );
}