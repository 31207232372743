import { TimeFormat } from "./TimeFormat";

export const buildHand = () => {
  const timeFormatArray: TimeFormat[] = [
    {
      format: 'h',
      separate: 360 / 12,
    },
    {
      format: 'm',
      separate: 360 / 60,
    },
    {
      format: 's',
      separate: 360 / 60,
    },
  ];
  return timeFormatArray;
};