import Maekochi from './maekochiToUrawa.json';
import dayjs from 'dayjs';

export type BusSchedule = {
  hour: number;
  minute: number[];
  day: number;
};

export const getBusSchedule = (time: number): BusSchedule[] => {
  // TODO 祝日が考慮できていない
  const h = dayjs(time).hour();
  const m = dayjs(time).minute();
  const d = dayjs(time).day();
  // console.log(d)

  const r1 = getNextArrival(h, m, d);
  const r2 = getNextArrival(r1.hour, r1.minute[r1.minute.length - 1] + 1, r1.day);
  const r3 = getNextArrival(r2.hour, r2.minute[r2.minute.length - 1] + 1, r2.day);
  return [r1, r2, r3];
};

const getNextArrival = (h: number, m: number, d: number): BusSchedule => {
  const arrival = Maekochi.arrivals[h];
  const ary: number[] = d == 0 ? arrival.sunday : d == 6 ? arrival.saturday : arrival.weekday;
  return ary.some(v => v >= m) ? { hour: h, minute: findArrival(ary, m), day: d } : 
    getNextArrival((h+1)%24, 0, h==23 ? (d+1)%7 : d);
}

const findArrival = (ary: number[], m: number): number[] => {
  return ary.filter(v => v >= m);
}