import React, { useEffect, useState } from "react";

export const useSeconds = (n: number = 1) => {
  const [seconds, updateSeconds] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => updateSeconds(Date.now()), 1000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [seconds]);
  return seconds;
}

export const useMinute = (n: number = 1) => {
  const [minute, updateMinute] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => updateMinute(Date.now()), 60000 * n)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [minute]);
  return minute;
}