import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { getBusSchedule } from '../../hooks/Dashboard/GetBusSchedule'
import { useMinute } from '../../hooks/Dashboard/TimeEffects';
import { DCard } from './DCard';

export type DBusScheduleProps = {
  height: number;
  width: number;
  backgroundColor: string;
}

export const DBusSchedule: React.FC<DBusScheduleProps> = (props) => {
  const time = useMinute();

  const commonProps = {
    hideable: true, hideSortIcons: true, disableColumnMenu: true,
    sortable: false, resizable: false, filterable: false,
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 1, ...commonProps},
    { field: 'arrivalHour', headerName: '時', width: 10, ...commonProps},
    { field: 'arrivalMinute', headerName: '分', width: 147, ...commonProps},
  ];

  // console.log(props.time);

  const schedule = getBusSchedule(time)
  const rows = schedule.map((s, i) => {
    return { id: i, arrivalHour: s.hour, arrivalMinute: s.minute.join(', ')}
  });

  return (
    <DCard width={props.width} height={props.height} message={''} title={'バス時刻表'} subTitle={'前耕地→浦和駅'} backgroundColor={props.backgroundColor}>
      <Box sx={{ height: 160, width: '100%' }}>
      <DataGrid 
        headerHeight={20}
        hideFooter={true}
        rowHeight={45}
        initialState={ {columns: { columnVisibilityModel: {id: false, day: true, garbage: true}}}}
        rows={rows}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableSelectionOnClick
        autoHeight={true}
      />
      </Box>
    </DCard>
  );
}