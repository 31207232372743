import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { buildHand } from '../atoms/BuildHand';
import { Hand } from '../atoms/Hand';
import { TimeFormat } from '../atoms/TimeFormat';

export type AnalogClockProps = {
  time: number;
};

export const AnalogClock: React.FC<AnalogClockProps> = ({ time }) => {
  const [hand, setHand] = useState<TimeFormat[]>([])
  useEffect(() => {
    setHand(buildHand());
  }, []);
  
  return (
    <SClockBoard>
      {hand.map((value, index) => (
        <Hand key={index} time={time} format={value.format} separate={value.separate} />
      ))}
    </SClockBoard>
  );
}

const SClockBoard = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid gray;
  border-radius: 100%;
  position: relative;
  margin-left: 20px;
  &:before {
    content: '';
    diplay: block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #6F7E8C;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`