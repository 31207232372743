import styled from '@emotion/styled'
// import { ClockNum } from 'interfaces/ClockNum'
// import { TimeFormat } from 'interfaces/TimeFormat'
import dayjs from 'dayjs'

// type Props = ClockNum & TimeFormat
export type HandProps = {
  time: number;
  format: string;
  separate: number;
};


export const Hand: React.FC<HandProps> = ({ time, format, separate }) => {
  const now: number = Number(dayjs(time).format(format));
  const Angle = now * separate;
  let handStyles
  if (format === 'h') {
    handStyles = {
      height: '42px',
      top: '32px',
    }
  }
  if (format === 'm') {
    handStyles = {
      height: '63px',
      top: '12px',
    }
  }
  if (format === 's') {
    handStyles = {
      height: '63px',
      top: '12px',
      width: '2px',
    }
  }

  const SSecHand = styled.div`
    width: 4px;
    background-color: #6F7E8C;
    border-radius: 100em;
    position: absolute;
    left: 50%;
    transform-origin: bottom center;
    transform: translateX(-50%) rotate(${Angle}deg);
    ${handStyles}
  `
  return <SSecHand />
};