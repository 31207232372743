import React, { useEffect, useState } from 'react'
import {Box, Typography} from '@mui/material'
import { DCard } from './DCard';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { fetchAPI } from '../../hooks/ExternalApi';
import { useMinute } from '../../hooks/Dashboard/TimeEffects';

export type DWeatherCardProps = {
  height: number;
  width: number;
};

type WeatherAPIForecastResponse = {
  date: string;
  dateLabel: string;
  telop: string;
  detail: {
    weather: string;
    wind: string;
    wave: string;
  };
  temperature: {
    min: {
      celsius: string;
      fahrenheit: string;
    };
    max: {
      celsius: string;
      fahrenheit: string;
    };
  };
  chanceOfRain: {
    T00_06: string;
    T06_12: string;
    T12_18: string;
    T18_24: string;
  };
  image: {
    title: string;
    url: string;
    width: number;
    height: number;
  };
}

type WeatherAPIProviderResponse = {
  link: string;
  name: string;
  note: string;
}

type WeatherAPIResponse = {
  publicTime: string;
  publicTimeFormatted: string;
  publishingOffice: string;
  title: string;
  link: string;
  description: {
    publicTime: string;
    publicTimeFormatted: string;
    headlineText: string;
    bodyText: string;
  };
  forecasts: WeatherAPIForecastResponse[],
  location: {
    area: string;
    prefecture: string;
    district: string;
    city: string;
  };
  copyright: {
    title: string;
    link: string;
    image: {
      title: string;
      link: string;
      url: string;
      width: number;
      height: number;
    };
    provider: WeatherAPIProviderResponse[];
  };
}

export const DWeatherCard: React.FC<DWeatherCardProps> = (props: DWeatherCardProps) => {
  const time = useMinute(10);
  
  const [data, setData] = useState<WeatherAPIResponse>();
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchAPI<WeatherAPIResponse>({
        url: 'https://weather.tsukumijima.net/api/forecast/city/110010',
        method: 'get',
        params: {
        }
      });
      if(res === undefined || res.data === undefined) return;
      setData(res.data);
    }
    fetch();
  }, []);

  // console.log(`called.${time}`);
  console.log(data);
  const style = {
    ...props,
    title: '天気',
    message: '読み込み中',
    backgroundColor: '#FE9616'
  };

  if(data === undefined) {
    return (
      <DCard {...style} />
    );
  } else {
    const todayForecast = data.forecasts[0];
    const commonProps = {
      hideable: true, hideSortIcons: true, disableColumnMenu: true,
      sortable: false, resizable: false, filterable: false,
    }
    const columns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 1, ...commonProps},
      { field: 't6oc', headerName: '6', width: 20, ...commonProps, headerAlign: 'center',},
      { field: 't12oc', headerName: '12', width: 20, ...commonProps, headerAlign: 'center',},
      { field: 't18oc', headerName: '18', width: 10, ...commonProps, headerAlign: 'center',},
      { field: 't24oc', headerName: '24', width: 10, ...commonProps, headerAlign: 'center',},
    ]
    const rows = [
      {id: 1, t6oc: todayForecast.chanceOfRain.T00_06, t12oc: todayForecast.chanceOfRain.T06_12, 
        t18oc: todayForecast.chanceOfRain.T12_18, t24oc: todayForecast.chanceOfRain.T18_24,
      },
    ];
    // console.log(rows);
    return (
      <DCard {...style} message={data.title}>
        <div>
          <Typography>{todayForecast.date}</Typography>
          <img src={todayForecast.image.url}/>
          <Box sx={{ height: 170, width: '100%' }}>
        <DataGrid 
        headerHeight={20}
        hideFooter={true}
        rowHeight={20}
        initialState={ {columns: { columnVisibilityModel: {id: false, day: true, garbage: true}}}}
        rows={rows}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableSelectionOnClick
        autoHeight={true}
      />
      </Box>
        </div>
      </DCard>
    );
  }

}
