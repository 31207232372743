import axios from 'axios'

export type ExternalAPIProps = {
  url: string;
  method: 'get' | 'post';
  params: any;
}

export const fetchAPI = async <T> (props: ExternalAPIProps) => {
  const api = axios.create({
    baseURL: props.url,
  });
  if(props.method == 'get') {
    return await api.get<T>('', { params: props.params });
  }
  else if (props.method == 'post'){
    return await api.post<T>('', { params: props.params});
  }
}