import React from 'react'
import {Box, Typography} from '@mui/material'
import { DCard } from './DCard';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMinute } from '../../hooks/Dashboard/TimeEffects';

export type DGarbageCardProps = {
  height: number;
  width: number;
};

export const DGarbageCard: React.FC<DGarbageCardProps> = (props: DGarbageCardProps) => {
  const time = useMinute();
  const d = new Date(time);
  const dayIndex = d.getDay();

  const rows = [
    { id: 1, garbage: 'ビン, ペットボトル\nカン, プラスチック', day: '金'},
    { id: 2, garbage: 'もえないゴミ, 紙\n段ボール, 危険ゴミ', day: '水'},
    { id: 3, garbage: '燃えるゴミ', day: '月,木'},
  ];
  const commonProps = {
    hideable: true, hideSortIcons: true, disableColumnMenu: true,
    sortable: false, resizable: false, filterable: false,
  }
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 1, ...commonProps},
    { field: 'garbage', headerName: '種類', width: 147, ...commonProps, headerAlign: 'center',
    renderCell: (params) => {
      return (
        <div className='DataGridCell'>
          {params.value.split(/(\n)/).map((msg: string, idx: number) => {
        return (
          <Typography fontSize={14} align={'center'} key={idx}>{msg}</Typography>
        );
      })}</div>);
    }
    },
    {
      field: 'day',
      headerName: '曜日',
      width: 10,
      ...commonProps,
      renderCell: (params) => {
        return (
          <Typography fontSize={13}>{params.value}</Typography>
        );
      }
    },
  ];

  return (
    <DCard title='ゴミの日' message={''} height={props.height} width={props.width} backgroundColor={'#13B0C5'}>
      <Box sx={{ height: 160, width: '100%' }}>
        <DataGrid 
        headerHeight={20}
        hideFooter={true}
        rowHeight={52}
        initialState={ {columns: { columnVisibilityModel: {id: false, day: true, garbage: true}}}}
        rows={rows}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableSelectionOnClick
        autoHeight={true}
      />
      </Box>
    </DCard>
  );
}
