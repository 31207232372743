import React, { ReactNode } from 'react'
import {Card, CardActionArea, CardMedia, CardContent, Typography} from '@mui/material'

export type DCardProps = {
  img_path?: string;
  title: string;
  subTitle?: string;
  message:string;
  url?:string;
  height:number;
  width: number;
  children?: ReactNode;
  backgroundColor?: string;
};

export const DCard: React.FC<DCardProps> = (props: DCardProps) => {
  const style = {
    height: props.height,
    width: props.width,
    // minWidth: props.width,
    // minHeight: props.height
    backgroundColor: props.backgroundColor,
  };

  const messages = props.message.split(/(\n)/).map((msg, idx) => {
    return (
      <React.Fragment key={idx}>
        {msg.match(/\n/) ? <br /> : msg}
      </React.Fragment>
    );
  });

  return (
    <div className="card-top">
    <Card style={style}>
      <CardContent>
        <Typography variant="h5" component="div">{props.title}</Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">{props.subTitle}</Typography>
        <Typography variant="body2" color="textSecondary">{messages}
        </Typography>
        {props.children}
      </CardContent>
    </Card>
    </div>
  );
}