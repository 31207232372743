import React from 'react';
import './App.css';
import { Dashboard } from './components/pages/Dashborad';
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from './components/molecules/LoginButton';
import { LogoutButton } from './components/molecules/LogoutButton';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="App">
      {!isAuthenticated ? ( <LoginButton/> ) : (<div><Dashboard/><LogoutButton/></div>) }
    </div>
  );
};

export default App;
